import { IEnvironment } from '@env/ienvironment';

export const environment: IEnvironment = {
	production: false,
	environmentName: 'QA',

	firebase: {},

	debug: false,
	log: {
		auth: false,
		store: false,
	},

	smartadmin: {
		api: null,
		db: 'smartadmin-angular'
	},

	identityApiUrl: 'https://api.nt-qa.icentral.io/identity',
	accountApiUrl: 'https://api.nt-qa.icentral.io/account-mgmt',
	commandingApiUrl: 'https://api.nt-qa.icentral.io/commanding',
	legacyBaseUrl: 'https://www-qa.irrigdev.net/iCentral',
	apiKey: 'CZSfxztfjSav4Fr4eRxr87ieYnMcuNo71pkkkjb3',

	youtubeUrl: 'https://www.youtube.com/user/RainMasterCS',

	awsAppSyncUrl: 'https://appsync.nt-qa.icentral.io/graphql',
	awsAppSyncRegion: 'us-west-2',
	awsAppSyncApiKey: 'da2-nm4tkguoynbo7jxzh3osqgwyiq'
};
